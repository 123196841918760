import TextObject from './text-object';
import { onDomReady } from '../../components/dynamic/observer';

class Scroll {
  constructor() {
    this.scrollTop = 0;
    this.lastScrollTop = 0;
    this.collectTextObjects();
    this.update();
    this.eventListeners();
  }

  collectTextObjects() {
    this.textObjects = [];
    Array.from(document.querySelectorAll('[data-scroll-container]'))
      .forEach((container) => {
        this.textObjects.push(new TextObject(container));
      });
  }

  eventListeners() {
    window.addEventListener('scroll', () => {
      this.scrollTop = window.pageYOffset;
    });
  }

  update() {
    this.textObjects.forEach((textObject) => {
      textObject.update((this.lastScrollTop - this.scrollTop) / 5);
    });
    this.lastScrollTop = this.scrollTop;

    window.requestAnimationFrame(this.update.bind(this));
  }
}

onDomReady(() => {
  new Scroll();
});