import { onDomReady } from "../../components/dynamic/observer";

class ContentsMenu {
  constructor() {
    this.items = document.querySelectorAll('[data-contents-item]');

    this.eventListeners();
  }

  eventListeners() {
    this.items.forEach((item) => {
      item.addEventListener('click', () => {
        document.dispatchEvent(new CustomEvent('pageNumberClick', {
          detail: {
            pageNumber: parseInt(item.dataset.contentsItem, 10),
          },
        }));

        document.dispatchEvent(new CustomEvent('contentsMenuSelectedPage'));
      });
    });
  }
}

onDomReady(() => {
    new ContentsMenu();
});