import {Controller, EffectCreative, EffectFade, Mousewheel, Navigation, Pagination, Swiper} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

function init() {
  const container = document.querySelector('[data-index-slider-container]');

  if (container) {
    const imageSliderEl = container.querySelector('[data-index-image-slider]');
    const textSliderEl = container.querySelector('[data-index-text-slider]');
    const counterEl = container.querySelector('[data-index-slider-counter]');

    let imageSlider;
    let textSlider;
    let counterSlider;

    const leftArrow = container.querySelector('[data-index-left]');
    const rightArrow = container.querySelector('[data-index-right]');
    const progress = container.querySelector('[data-index-slider-progress]');

    if (imageSliderEl) {
      imageSlider = new Swiper(imageSliderEl, {
        modules: [Navigation, Pagination, EffectCreative, Controller],
        speed: 1800,
        preventInteractionOnTransition: true,
        loop: true,
        navigation: {
          prevEl: leftArrow,
          nextEl: rightArrow,
        },
        pagination: {
          el: progress,
          type: 'progressbar',
        },
        effect: 'creative',
        creativeEffect: {
          prev: {
            translate: [0, 0, 1],
          },
          current: {
            translate: [0, 0, 0],
          },
          next: {
            translate: [0, 0, 1],
          },
        },
      });
    }

    if (textSliderEl) {
      textSlider = new Swiper(textSliderEl, {
        modules: [EffectFade, Controller],
        allowTouchMove: false,
        preventInteractionOnTransition: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
      });
    }

    if (counterEl) {
      counterSlider = new Swiper(counterEl, {
        allowTouchMove: false,
        modules: [Controller],
        direction: 'vertical',
        preventInteractionOnTransition: true,
      });
    }
  }
}

onDomReady(() => setTimeout(() => init(), 100));