import IMask from 'imask';
import Jax from '../../components/jax/jax.js';

const initPageNumberField = () => {
  document.querySelectorAll('[data-page-number-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = 'true';

    const mask = IMask(input, {
      mask: /^[0-9-]*$/,
    });

    mask.on('complete', () => {
      if (input.dataset.leadUrl) {
        const jax = new Jax(input.dataset.leadUrl, 'POST');
        jax.send({
          pageNumber: input.value,
        });
      }
    });
  });
};

document.addEventListener('DOMContentLoaded', initPageNumberField);
document.addEventListener('DOMContentMutated', initPageNumberField);