import {Controller, EffectCreative, EffectFade, Navigation, Pagination, Swiper} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const container = document.querySelector('[data-employe-slider-container]');

  if (container) {
    const imageSliderEl = container.querySelector('[data-employe-image-slider]');
    const textSliderEl = container.querySelector('[data-employe-text-slider]');
    const counterEl = container.querySelector('[data-employe-slider-current]');

    let textSlider;
    let counterSlider;
    let imageSlider;

    const progress = container.querySelector('[data-employe-progress]');
    const leftArrow = container.querySelector('[data-employe-left]');
    const rightArrow = container.querySelector('[data-employe-right]');

    if (imageSliderEl) {
      imageSlider = new Swiper(imageSliderEl, {
        modules: [Navigation, Pagination, EffectCreative, Controller],
        speed: 1000,
        loop: true,
        preventInteractionOnTransition: true,
        navigation: {
          prevEl: leftArrow,
          nextEl: rightArrow,
        },
        pagination: {
          el: progress,
          type: 'progressbar',
        },
        effect: 'creative',
        creativeEffect: {
          prev: {
            translate: [0, 0, 1],
          },
          current: {
            translate: [0, 0, 0],
          },
          next: {
            translate: [0, 0, 1],
          },
        },
        breakpoints: {
          320: {
            allowTouchMove: true,
          },
          768: {
            allowTouchMove: false,
          },
          1200: {
            allowTouchMove: true,
          },
        },
      });
    }

    if (textSliderEl) {
      textSlider = new Swiper(textSliderEl, {
        modules: [EffectFade],
        allowTouchMove: false,
        preventInteractionOnTransition: true,
        autoHeight: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
      });
    }

    if (counterEl) {
      counterSlider = new Swiper(counterEl, {
        modules: [EffectCreative],
        direction: 'vertical',
        preventInteractionOnTransition: true,
      });
    }

    imageSlider.on('slideChange', () => {
      textSlider.slideTo(imageSlider.realIndex, 1000);
      counterSlider.slideTo(imageSlider.realIndex, 1000);
    });

    document.addEventListener('Employee-menu-opened', (e) => {
      imageSlider.slides.forEach((slide) => {
        slide.style.transitionDuration = '0s !important';
      });

      imageSlider.slideTo(e.detail.employeeId, 1);

      setTimeout(() => {
        imageSlider.slides.forEach((slide) => {
          slide.style.transitionDuration = '0s !important';
        });
      }, 1000);
    });
  }
});