import {onDomReady} from "../../components/dynamic/observer";

class HoverListener {
    items = undefined;
    targetItems = undefined;
    order = undefined;
    currentState = undefined;
    lastState = undefined;
    busy = undefined;
    animTimeout = undefined;
    notActive = undefined;

    constructor(selector) {
        if (selector) {
            this.itemList = selector.querySelector('[data-hover-action-list]');

            this.items = Array.from(selector.querySelectorAll('[data-hovered-item]:not([data-initialized])'));
            this.targetItems = Array.from(selector.querySelectorAll('[data-hover-target]:not([data-initialized])'));

            this.order = [];
            this.busy = false; // true - анимация идет, false - анимация не идет.

            this.notActive = true;

            this.animTimeout = 400;

            this.eventListeners();
        }
    }

    eventListeners() {
        this.catalogItemsMouseEnterListener();
        if (this.itemList) {
            this.listItemsListener();
        }
    }

    defineOrder(item) {
        const instance = this;

        if (!this.state) {
            instance.setTargetToInactive();
            instance.setActiveToTarget(item);
            this.state = true;
            this.timeoutHandler();
        } else {
            instance.addToOrder(item);
        }
    }

    timeoutHandler() {
        const instance = this;

        setTimeout(() => {
            if (instance.notActive) {
                this.order = [];
            }

            const length = this.order.length;

            if (length) {
                const lastItem = instance.getLastItem();
                this.order = [];
                instance.setTargetToInactive();
                instance.setActiveToTarget(lastItem);
                instance.timeoutHandler();
            } else {
                this.state = false;
                if (instance.notActive) {
                    instance.setTargetToInactive();
                    this.targetItems[0].classList.add('_active');
                }
            }
        }, instance.animTimeout);
    }

    setActiveToTarget(item) {
        const instance = this;
        instance.targetItems.forEach((targetItem) => {
            if (targetItem.dataset.hoverTarget === item.dataset.hoveredItem) {
                this.state = true;
                targetItem.classList.add('_active');
            }

            setTimeout(() => {
                this.state = false;
            }, instance.animTimeout);
        });
    }

    setTargetToInactive() {
        const instance = this;
        instance.targetItems.forEach((targetItem) => {
            targetItem.classList.remove('_active');
        })
    }

    getLastItem() {
        const length = this.order.length;
        if (length) {
            return this.order[length - 1];
        }
    }

    catalogItemsMouseEnterListener() {
        const instance = this;
        instance.items.forEach((item) => {
            item.addEventListener('mouseenter', () => {
                instance.defineOrder(item);
                instance.addToOrder(item);
            });
        });
    }

    listItemsListener() {
        this.itemList.addEventListener('mouseenter', () => {
            this.notActive = false;
        });

        this.itemList.addEventListener('mouseleave', () => {
            this.notActive = true;
            this.timeoutHandler();
        });
    }

    addToOrder(item) {
        this.order.push(item);
    }
}

onDomReady(() => {
    document.querySelectorAll('[data-hovered-container]').forEach((item) => {
        const hoverListener = new HoverListener(item);
    });
})