import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class Follower {
    limits = undefined;
    offsetY = undefined;
    ease = undefined;
    observeAreas = [];
    cursor = undefined;
    lastActiveImage = undefined;
    largeMinWidth = undefined;
    item = undefined;
    blocked = false;
    images = [];

    constructor(selector) {
        selector.dataset.initialize = "true";
        this.container = selector;

        if (selector.dataset.followerContainer) {
            this.limits = JSON.parse(selector.dataset.followerContainer);
        }

        this.imagesContainer = selector.querySelector('[data-follow-picture-list]');
        this.images = Array.from(this.imagesContainer.querySelectorAll('[data-follow-picture-item]'));

        const rootStyles = getComputedStyle(document.documentElement);
        this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1200;

        this.offsetY = 0;
        this.ease = 0.05;
        this.cursor = {
            x: 0,
            y: 0,
        };
        this.currentImage = {
            x: 0,
            y: 0,
        };
        this.lastActiveImage = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };


        if (document.body.getBoundingClientRect().width >= this.largeMinWidth) {
            this.bindObserveAreas();
            this.eventListeners();
            this.update();
        }
    }

    bindObserveAreas() {
        this.observeAreas = Array.from(this.container.querySelectorAll('[data-follow-item]'));
    }

    update() {
        if (this.imagesContainer
            && this.images
            && this.container.closest('[data-modal-menu]').classList.contains('_open')
        ) {
            this.lerp();
            this.setImageY();
            this.setImageX();
        }
        const instance = this;
        window.requestAnimationFrame(() => {
            instance.update();
        });
    }

    setImageX() {
        this.imagesContainer.style.left = `${this.currentImage.x}px`;
    }

    setImageY() {
        this.imagesContainer.style.top = `${this.currentImage.y}px`;
    }

    computedY() {
        this.lastActiveImage.y = this.cursor.y - this.lastActiveImage.height / 2;
    }

    computedX() {
        if (this.item) {
            this.lastActiveImage.x = this.cursor.x - this.lastActiveImage.width / 2;

            const boundingItem = this.item.getBoundingClientRect();

            if (this.limits) {
                if (this.cursor.x < (boundingItem.left + (boundingItem.width * this.limits.left))) {
                    this.lastActiveImage.x = boundingItem.left + (boundingItem.width * this.limits.left) - this.lastActiveImage.width / 2;
                } else if (this.cursor.x > (boundingItem.left + (boundingItem.width * this.limits.right))) {
                    this.lastActiveImage.x = boundingItem.left + (boundingItem.width * this.limits.right) - this.lastActiveImage.width / 2;
                } else {
                    this.lastActiveImage.x = this.cursor.x - this.lastActiveImage.width / 2;
                }
            } else {
                this.lastActiveImage.x = this.cursor.x - this.lastActiveImage.width / 2;
            }
        }
    }

    setImageVisible(item) {
        item.querySelector('[data-follow-picture]').style.height = '100%';
    }

    removeVisibleFromImage(item) {
        item.querySelector('[data-follow-image]').style.transform = 'scale(1)';
        item.querySelector('[data-follow-picture]').style.height = '0';
        setTimeout(() => {
            item.querySelector('[data-follow-image]').style.transform = 'scale(1.4)';
        }, 800);
    }

    lerp() {
        this.currentImage.x = (1 - this.ease) * this.currentImage.x + this.ease * this.lastActiveImage.x;
        this.currentImage.y = (1 - this.ease) * this.currentImage.y + this.ease * this.lastActiveImage.y;
    }

    eventListeners() {
        const instance = this;

        document.addEventListener('scroll', () => {
            this.offsetY = window.pageYOffset;
        });

        window.addEventListener('mousemove', (e) => {
            instance.cursor.x = e.clientX;
            instance.cursor.y = e.clientY;

            this.computedY();
            this.computedX();
        });

        instance.observeAreas.forEach((item) => {
            const itemImage = this.images.find((image) => image.dataset.followPictureItem === item.dataset.followItem);

            if (!this.lastActiveImage.x) {
                this.lastActiveImage.x = window.innerWidth - itemImage.offsetWidth;
            }

            if (itemImage) {
                item.addEventListener('mouseenter', () => {
                    itemImage.querySelector('[data-follow-image]').style.transform = 'scale(1.2)';

                    if (this.item !== item) {
                        if (this.item) {
                            itemImage.style.alignItems = 'flex-start';
                            itemImage.querySelector('[data-follow-image]').style.top = '0';
                            itemImage.querySelector('[data-follow-image]').style.bottom = 'unset';

                            const prevItemImage = this.images.find((image) => image.dataset.followPictureItem === this.item.dataset.followItem);
                            prevItemImage.style.zIndex = '10';
                            itemImage.style.zIndex = '5';

                            prevItemImage.querySelector('[data-follow-picture]').style.transitionDuration = '.8s';
                            prevItemImage.querySelector('[data-follow-picture]').style.height = '0';

                            itemImage.querySelector('[data-follow-picture]').style.transitionDuration = '0s';
                            itemImage.querySelector('[data-follow-picture]').style.height = '100%';
                        } else {
                            itemImage.style.alignItems = 'flex-end';
                            itemImage.querySelector('[data-follow-image]').style.top = 'unset';
                            itemImage.querySelector('[data-follow-image]').style.bottom = '0';

                            this.setImageVisible(itemImage);
                        }
                    }

                    this.item = item;

                }, false);

                item.addEventListener('mouseleave', () => {
                    itemImage.style.alignItems = 'flex-start';
                    itemImage.querySelector('[data-follow-image]').style.top = '0';
                    itemImage.querySelector('[data-follow-image]').style.bottom = 'unset';

                    instance.removeVisibleFromImage(itemImage);
                    this.images.forEach(image => image.querySelector('[data-follow-picture]').style.transitionDuration = '.8s');
                }, false);
            }
        });

        this.container.addEventListener('mouseleave', () => {
            this.item = undefined;
        }, false);

        const bounding = this.imagesContainer.getBoundingClientRect();
        this.lastActiveImage.width = bounding.width;
        this.lastActiveImage.height = bounding.height;

        window.addEventListener('resize', (e) => {
            this.lastActiveImage.width = bounding.width;
            this.lastActiveImage.height = bounding.height;
        });
    }
}

function init() {
    let containers = document.querySelectorAll('[data-follower-container]:not([data-initialize="true"])');
    containers.forEach((item) => {
        const follower = new Follower(item);
    });
}

onDomReady(() => {init()});
onDomChanges(() => {init()});