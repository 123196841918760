import { onDomReady } from '../../components/dynamic/observer';

class UrlChanger {
  constructor(selector) {
    this.container = selector;
    this.catalog = this.container.querySelector('[data-page-flip]');
    this.items = this.container.querySelectorAll('[data-page-flip-item]');
    this.urls = Array.from(this.items).map((item) => item.getAttribute('data-catalog-url'));

    this.downloadButtons = this.container.querySelectorAll('[data-catalog-download]');
    this.leftButton = this.downloadButtons[0];
    this.rightButton = this.downloadButtons[1];

    this.isSinglePage = false;

    this.singlePageChecker();
    this.eventListeners();
  }

  eventListeners() {
    document.addEventListener('updateCurrentPageIndex', (e) => {
      this.currentPageIndex = e.detail.activePage;
      this.setButtonsUrl();
    });

    window.addEventListener('resize', () => {
      this.singlePageChecker();
      this.setButtonsUrl();
    });
  }

  singlePageChecker() {
    if (this.catalog.firstElementChild.classList.contains('--portrait')) {
      this.isSinglePage = true;
      this.catalog.classList.add('_single-page');
    } else if (this.catalog.firstElementChild.classList.contains('--landscape')) {
      this.isSinglePage = false;
      this.catalog.classList.remove('_single-page');
    }
  }

  setButtonsUrl() {
    if (this.isSinglePage) {
      this.portraitModeAction(this.currentPageIndex);
    } else if (this.currentPageIndex + 1 === 1) {
      // if first page
      this.firstPageAction(this.currentPageIndex);
    } else if (this.currentPageIndex + 1 === this.items.length) {
      // if last page
      this.lastPageAction(this.currentPageIndex);
    } else {
      // all another pages
      this.middlePageAction(this.currentPageIndex);
    }
  }

  portraitModeAction(currentPageIndex) {
    this.setButtonHidden(this.leftButton);

    this.setButtonVisible(this.rightButton);
    this.clearButtonUrl(this.rightButton);
    this.setButtonUrl(this.rightButton, this.urls[currentPageIndex]);
  }

  firstPageAction(currentPageIndex) {
    this.clearButtonUrl(this.leftButton);
    this.setButtonHidden(this.leftButton);

    this.setButtonVisible(this.rightButton);
    this.setButtonUrl(this.rightButton, this.urls[currentPageIndex]);
  }

  lastPageAction(currentPageIndex) {
    this.clearButtonUrl(this.rightButton);
    this.setButtonHidden(this.rightButton);

    this.setButtonVisible(this.leftButton);
    this.setButtonUrl(this.leftButton, this.urls[currentPageIndex]);
  }

  middlePageAction(currentPageIndex) {
    this.setButtonVisible(this.leftButton);
    this.setButtonUrl(this.leftButton, this.urls[currentPageIndex]);

    this.setButtonVisible(this.rightButton);
    this.setButtonUrl(this.rightButton, this.urls[currentPageIndex + 1]);
  }

  setButtonUrl(button, url) {
    button.setAttribute('href', url);
  }

  clearButtonUrl(button) {
    button.setAttribute('href', '');
  }

  setButtonHidden(button) {
    button.classList.add('_hidden');
  }

  setButtonVisible(button) {
    button.classList.remove('_hidden');
  }
}

onDomReady(() => {
  const catalogElement = document.querySelector('[data-page-flip-container]');
  if (catalogElement) {
    new UrlChanger(catalogElement);
  }
});