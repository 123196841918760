class TextObject {
  constructor(container) {
    this.textContainer = container;
    this.text = this.textContainer.querySelector('[data-scroll-text]');
    this.textItems = this.text.querySelectorAll('[data-scroll-text-item]');
    this.offset = 0;
    this.selfOfset = 0;
    this.direction = this.textContainer.getAttribute('data-scroll-container');
  }

  computedOffset(scrollOffset) {
    if (this.direction === 'right') {
      this.selfOfset++;
    } else if (this.direction === 'left') {
      this.selfOfset--;
    }

    this.computedOffsetByScroll(scrollOffset);

    this.offset = this.selfOfset / 2;

    if (this.textItems.length <= 1) {
      throw new Error('Not enough elements in rail container');
    }

    if (Math.abs(this.offset) >= this.textItems[1].offsetWidth) {
      this.offset = this.offset % this.textItems[1].offsetWidth;
    }
    this.setStyles();
  }

  computedOffsetByScroll(scrollOffset) {
    if (window.innerWidth >= 1200) {
      switch (this.direction) {
        case 'right':
          this.selfOfset += Math.abs(scrollOffset);
          break;
        case 'left':
          this.selfOfset += Math.abs(scrollOffset) * -1;
          break;
        default:
          throw new Error('Undefined type of rail direction');
      }
    }
  }

  setStyles() {
    this.text.style.transform = `translate3d(${this.offset}px, 0, 0)`;
  }

  update(scrollOffset) {
    this.computedOffset(scrollOffset);
  }
}

export default TextObject;