import { PageFlip } from 'page-flip';
import { debounce } from 'lodash';
import { onDomReady } from '../../../components/dynamic/observer';

class PageFlipCreator {
  constructor(selector) {
    this.container = selector;
    this.pageFlipElement = this.container.querySelector('[data-page-flip]');
    this.prev = this.container.querySelector('[catalog-slider-left]');
    this.next = this.container.querySelector('[catalog-slider-right]');
    this.input = this.container.querySelector('[data-page-number-field]');
    this.maxPageNumber = parseInt(this.input.getAttribute('data-max-length'), 10);

    if (!this.pageFlipElement) {
      throw new Error('Page flip element not found by selector "data-page-flip"');
    }

    this.images = this.pageFlipElement.querySelectorAll('[data-page-flip-item]');

    this.init();

    this.initUrlParams();
    this.setCurrentPage();

    this.eventListeners();

    if (window.innerWidth < 1200) {
      this.pageFlip.setting.maxWidth = 771;
      this.pageFlip.setting.maxHeight = 1088;
    }
  }

  initUrlParams() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
  }

  setCurrentPage() {
    const currentPage = this.urlParams.get('currentPage');

    if (!currentPage && history.pushState) {
      this.setCurrentPageToUrl(1);
    } else {
      setTimeout(() => {
        this.pageFlip.turnToPage(parseInt(currentPage, 10));
      }, 500);
    }
  }

  setCurrentPageToUrl(pageNumber) {
    const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const newUrl = `${baseUrl}?currentPage=${pageNumber}`;
    history.pushState(null, null, newUrl);
  }

  init() {
    this.createPageFlip();

    if (!this.pageFlip) {
      throw new Error('Page flip did not initialized');
    }

    this.loadImages();
  }

  createPageFlip() {
    this.pageFlip = new PageFlip(this.pageFlipElement, {
      width: 540,
      height: 764,

      size: 'stretch',
      minWidth: 346,
      maxWidth: 540,
      minHeight: 488,
      maxHeight: 764,

      maxShadowOpacity: 0.5,
      showCover: true,
      mobileScrollSupport: true,
    });
  }

  loadImages() {
    this.pageFlip.loadFromHTML(this.images);
  }

  eventListeners() {
    this.prev.addEventListener('click', () => {
      this.pageFlip.flipPrev();
    });

    this.next.addEventListener('click', () => {
      this.pageFlip.flipNext();
    });

    this.input.addEventListener('input', debounce(() => {
      const valueInputNumber = parseInt(this.input.value, 10);

      if (valueInputNumber >= this.maxPageNumber
          && this.pageFlip.getCurrentPageIndex() + 1 === this.maxPageNumber) {
        this.input.value = this.maxPageNumber;
      }

      if (valueInputNumber < 1 && this.pageFlip.getCurrentPageIndex() + 1 === 1) {
        this.input.value = 1;
      }

      if (valueInputNumber >= this.maxPageNumber) {
        this.flipToInputValue(this.maxPageNumber);
      } else if (valueInputNumber < 1) {
        this.flipToInputValue(0);
      } else {
        this.flipToInputValue(valueInputNumber);
      }
    }, 2000));

    this.pageFlip.on('init', (e) => {
      this.setInputValue(e.data.page + 1);
      this.dispatchUpdateCurrentPageIndex();
    });

    this.pageFlip.on('flip', (e) => {
      this.setInputValue(e.data + 1);
      this.dispatchUpdateCurrentPageIndex();

      const currentPage = this.pageFlip.getCurrentPageIndex() + 1;
      this.setCurrentPageToUrl(currentPage);
    });

    document.addEventListener('pageNumberClick', (e) => {
      this.pageFlip.flip(e.detail.pageNumber - 1);
    });
  }

  dispatchUpdateCurrentPageIndex() {
    document.dispatchEvent(new CustomEvent('updateCurrentPageIndex', {
      detail: {
        activePage: this.pageFlip.getCurrentPageIndex(),
      },
    }));
  }

  flipToInputValue(pageNumber) {
    this.pageFlip.flip(pageNumber - 1);
  }

  setInputValue(pageNumber) {
    if (pageNumber === 1) {
      this.input.value = 1;
    } else if (pageNumber === this.maxPageNumber) {
      this.input.value = this.maxPageNumber;
    } else if (window.innerWidth > 1200) {
      this.input.value = `${pageNumber}-${pageNumber + 1}`;
    } else {
      this.input.value = pageNumber;
    }
  }
}

onDomReady(() => {
  const pageFlipElement = document.querySelector('[data-page-flip-container]');
  if (pageFlipElement) {
    new PageFlipCreator(pageFlipElement);
  }
});