import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    const infoBlock = document.querySelector('[data-header-info]');
    if (infoBlock) {
        const layout = document.querySelector('.layout');
        const header = document.querySelector('[data-header]');
        const closer = document.querySelector('[data-header-info-closer]');
        closer.addEventListener('click', () => {
            layout.classList.remove('layout_first-visit');
            header.classList.remove('header_info');
            infoBlock.classList.add('_hidden');
        });
    }
});