import { Swiper, Pagination, FreeMode } from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

class AboutSlider {
  constructor(element) {
    this.container = element;
    this.sliderElement = this.container.querySelector('[data-about-slider]');
    this.progress = this.container.querySelector('[data-about-slider-progress]');
    this.startXPosition = 0;

    const rootStyles = getComputedStyle(document.documentElement);
    this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1200;

    this.drag = false;

    this.init();
    this.update();
  }

  init() {
    this.initSlider();
    this.bindDrag();
  }

  update() {
    this.toogleZoom();
    window.requestAnimationFrame(this.update.bind(this));
  }

  toogleZoom() {
    if (this.drag) {
      this.sliderElement.classList.add('_zoom');
    } else {
      this.sliderElement.classList.remove('_zoom');
    }
  }

  bindDrag() {
    this.slider.on('touchStart', (s, e) => {
      this.handleTouchStartEvent(e);
    });

    this.slider.on('touchEnd', (s, e) => {
      this.handleTouchEndEvent(e);
    });

    this.slider.on('touchMove', (s, e) => {
      const event = new CustomEvent('cursorMove', {
        detail: {
          offsetX: e.x,
          offsetY: e.y,
        },
      });
      window.dispatchEvent(event);
    });
  }

  handleTouchStartEvent(e) {
    this.drag = true;
    this.setStartXPosition(e);
    this.sendCursorScaleEvent(true);
  }

  handleTouchEndEvent(e) {
    this.drag = false;
    this.handleEndXPosition(e);
    this.sendCursorScaleEvent(false);
  }

  handleEndXPosition(e) {
    if (window.innerWidth >= this.largeMinWidth) {
      this.handleDesktopEndXPosition(e);
    } else {
      this.handleMobileEndXPosition(e);
    }
  }

  setStartXPosition(e) {
    if (window.innerWidth >= this.largeMinWidth) {
      this.startXPosition = e.x;
    } else {
      this.startXPosition = e.touches[0].clientX;
    }
  }

  handleDesktopEndXPosition(e) {
    if (this.startXPosition === e.x) {
      this.sendClickToItemEvent(e);
    }
  }

  handleMobileEndXPosition(e) {
    const deltaX = e.changedTouches[0].clientX - this.startXPosition;
    if (deltaX === 0) {
      this.sendClickToItemEvent(e);
    }
  }

  sendClickToItemEvent(e) {
    window.dispatchEvent(new CustomEvent('Employee-item-click', {
      detail: {
        button: e.target.closest('[data-menu-open]'),
      },
    }));
  }

  sendCursorScaleEvent(scaleValueBoolValue) {
    const event = new CustomEvent('cursorScale', {
      detail: {
        scale: scaleValueBoolValue,
      },
    });
    window.dispatchEvent(event);
  }

  initSlider() {
    this.slider = new Swiper(this.sliderElement, {
      modules: [Pagination, FreeMode],
      freeMode: true,
      pagination: {
        el: this.progress,
        type: 'progressbar',
      },
      breakpoints: {
        320: {
          slidesPerView: 1.838,
          spaceBetween: 52,
        },
        768: {
          slidesPerView: 2.33,
          spaceBetween: 68,
        },
        1200: {
          slidesPerView: 'auto',
          spaceBetween: 130,
        },
      },
    });
  }
}

onDomReady(() => {
  const sliderContainer = document.querySelector('[data-about-slider-container]');
  if (sliderContainer) {
    new AboutSlider(sliderContainer);
  }
});