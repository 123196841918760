import {Controller, EffectCreative, Navigation, Pagination, Swiper} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const containers = document.querySelectorAll('[data-article-slider-container]');

  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-article-slider]');
      const textSliderEl = container.querySelector('[data-article-text-slider]');

      let imageSlider;
      let textSlider;

      const leftArrow = container.querySelector('[data-article-slider-left]');
      const rightArrow = container.querySelector('[data-article-slider-right]');

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          modules: [Controller, Navigation, Pagination, EffectCreative],
          slidesPerView: 1,
          speed: 1500,
          preventInteractionOnTransition: true,
          navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
          },
          effect: 'creative',
          creativeEffect: {
            prev: {
              translate: ['-50%', 0, -1],
            },
            current: {
              translate: [0, 0, -1],
            },
            next: {
              translate: ['100%', 0, 1],
            },
          },
        });
      }

      if (textSliderEl) {
        textSlider = new Swiper(textSliderEl, {
          modules: [Controller, EffectCreative],
          speed: 1500,
          slidesPerView: 1,
          preventInteractionOnTransition: true,
          effect: 'creative',
          creativeEffect: {
            prev: {
              translate: [0, '-100%', 0],
            },
            next: {
              translate: [0, '100%', 1],
            },
          },
        });
      }

      if (imageSlider) {
        imageSlider.on('slideChange', () => {
          textSlider.slideTo(imageSlider.realIndex, 1500);
        });
      }
    }
  });
});